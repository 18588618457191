import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

// Images
import ImageTablet from './assets/hero-banner-tablet.png'
import ImageLG from './assets/hero-banner-desktopLG.png'
import ImageXL from './assets/hero-banner-desktopXL.png'
import ImageXXXL from './assets/hero-banner-desktopXXXL.png'

export const Image = styled.div`
  width: 100%;
  overflow: hidden;
  display: none;

  @media ${device.tablet} {
    display: block;
    height: 553px;
    background: url(${ImageTablet}) ${orange.extra} no-repeat top 36px center;
    border-radius: 0px 0px 0px 120px;
    padding-top: 356px;
  }

  @media ${device.desktopLG} {
    background: url(${ImageLG}) ${orange.extra} no-repeat top 56px center;
    width: 50%;
    height: 100vh;
    position: absolute;
    border-radius: 0px 160px 0px 0px;
    padding-top: 467.5px;

    h1 {
      max-width: 462px;
    }
  }

  @media ${device.desktopXL} {
    background: url(${ImageXL}) ${orange.extra} no-repeat top 64px center;
    border-radius: 0px 160px 0px 0px;
    padding-top: 456px;

    h1 {
      max-width: 651px;
    }
  }

  @media ${device.desktopXXXL} {
    background: url(${ImageXXXL}) ${orange.extra} no-repeat top 93px center;
    padding-top: 510px;
    background-size: 550px;

    h1 {
      font-size: 72px;
      line-height: 90px;
      max-width: 739px;
    }

    p {
      font-size: 48px;
      line-height: 60px;
    }
  }
`
export const Wrapper = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
  padding-bottom: 48px;

  @media ${device.tablet} {
    min-height: auto;
  }

  @media ${device.desktopLG} {
    height: 100vh;
    overflow: auto;
  }

  input::placeholder {
    font-weight: 700;
    color: ${grayscale[300]};
  }
`
