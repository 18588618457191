import React, { useEffect, useState } from 'react'
import * as S from './style'

import OpenAccountPFForm from 'src/components/UI/Forms/OpenAccounPFForm'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import { getParameterByName } from 'src/shared/helpers'

import { alphaCode } from 'src/utils/alpha-code-utms'

const AbrirContaPF = () => {
  const [ utmSource, setUtmSource ] = useState<string>('9io9bkj')
  const [ utmCampaign, setUtmCampaign ] = useState<string>('site_contadigital_aquisicao')

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', '') as string || '9io9bkj')
    setUtmCampaign(getParameterByName('utm_campaign', '') as string || 'site_contadigital_aquisicao')
  }, [])

  return (
    <Layout pageContext={pageContext}>
      <S.Wrapper>
        <S.Image>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex flex-column align-items-center'>
                <h1 className='fs-40 lh-50 fw-600 text-black text-center font-sora mb-3'>Cartão de crédito sem anuidade</h1>
                <p className='fs-32 lh-40 fw-600 text-white text-center font-sora mb-5 pb-lg-3'>com programa de pontos</p>
                <span className='d-block fs-16 lh-20 fs-xl-20 lh-xl-25 fw-600 text-white text-right font-sora mr-3 mr-lg-5 mt-n2 mt-lg-0 align-self-end'>Sujeito a análise</span>
              </div>
            </div>
          </div>
        </S.Image>
        <div className='row mx-0 justify-content-center'>
          <div className='col-12 col-lg-6' />
          <div className='col-12 col-lg-6 col-xl-5 px-0 pt-md-5'>
            <OpenAccountPFForm
              customOrigin='LP Conta Digital Inter'
              mobileLink={`https://bancointer.go.link?adj_t=${alphaCode(utmSource)}&adj_campaign=${utmCampaign}&adj_adgroup=contadigital&adj_creative=lp-abertura-cartao&adj_label=aquisicao&adj_fallback=https%3A%2F%2Finter.co%2F&adj_redirect_macos=https%3A%2F%2Finter.co%2F&adj_redirect_ios=https%3A%2F%2Fgointer.app%2F`}
            />
          </div>
        </div>
      </S.Wrapper>
    </Layout>
  )
}

export default AbrirContaPF
